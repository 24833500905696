import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useState, useEffect} from "react";
import logo from '../assets/img/logo.png'; 
import social1 from '../assets/img/nav-icon1.svg'; 
import social2 from '../assets/img/nav-icon2.svg'; 
import social3 from '../assets/img/nav-icon3.svg'; 
import BotonWhatsApp from './BotonWhasspa';

export const NavBar = () => {
    const [ activelink, setActiveLink] = useState("home");
    const [scolled, seScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50){
                seScrolled(true);
            } else{
                seScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    },[])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }


  return (
    <Navbar expand="lg" className={scolled ? "scrolled": ""}>
      <Container>
        <Navbar.Brand href="#home">
            <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
        <span className='navbar-toggler-icon'></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" className={activelink === "home" ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("home")}>Home</Nav.Link>
            <Nav.Link href="#skills" className={activelink === "skills" ? "active navbar-link" : "navbar-link"}onClick={() => onUpdateActiveLink("skills")}>Skills</Nav.Link>
            <Nav.Link href="#projects" className={activelink === "projects" ? "active navbar-link" : "navbar-link"}onClick={() => onUpdateActiveLink("projects")}>Projects</Nav.Link>
           </Nav>
           <spam className="navbar-text">
            <div className='social-icon'>
                <a href="https://www.linkedin.com/company/websco-digital"><img src={social1} alt="instagram" /></a>
                <a href="https://www.facebook.com/websco.tech"><img src={social2} alt="facebook" /></a>
                <a href="https://www.instagram.com/websco.digital/"><img src={social3} alt="linkedin" /></a>

            </div>
            <button  href="#projects" className={activelink === "contact" ? "active navbar-link" : "navbar-link"}onClick={() => onUpdateActiveLink("contact")}><span>Let's Connect</span>

            </button>
           </spam>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar; 