import { Container, Col, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from "../assets/img/meter1.png";
import meter2 from "../assets/img/meter2.png";
import meter3 from "../assets/img/meter3.png";
import colorSharp from "../assets/img/banner-bg.png";
import colorSharp2 from "../assets/img/color-sharp2.png";




export const Skills =()=>{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
      return(
        <section className="skill" id="skills">
            <Container>
                <Row>
                   <Col>
                    <div className="skill-bx">
                        <h2>
                            Skills
                        </h2>
                    <p>
Websco digital has experience in the creation of digital strategies for projects of all kinds, we start from the investigation of the project environment to generate specific impact strategies, we work with the expectation of growth and channeling the objectives of each of our clients .</p>
                    <Carousel responsive={responsive} infinite={true} className="skills-sllider">
                      <div className="item">
                            <img src={meter1} alt="Image" />
                     <h4>Web Development</h4>
                     </div>
                     <div className="item">
                            <img src={meter2} alt="Image" />
                     <h4>Branding</h4>
                     </div> 
                     <div className="item">
                            <img src={meter3} alt="Image" />
                     <h4>Digital Marketing</h4>
                     </div>
                     <div className="item">
                            <img src={meter2} alt="Image" />
                     <h4>UX Design</h4>
                     </div>
                     <div className="item">
                            <img src={meter1} alt="Image" />
                     <h4>Web Design</h4>
                     </div>
                    </Carousel>
                    </div>
                   </Col> 
                </Row>


            </Container>
        </section>
      )

}