import React from "react";
import { Link } from "react-router-dom";

function BotonWhatsApp() {
  const numeroTelefono = "+573175741844"; // reemplace con el número de teléfono deseado

  const url = `https://wa.me/${numeroTelefono}`;

  return (
    <Link to={url} target="_blank">
      <button>Contacto</button>
    </Link>
  );
}

export default BotonWhatsApp;