import { Container, Col, Row } from "react-bootstrap";
import logo from "../assets/img/logo_blanco.png"
import social1 from '../assets/img/nav-icon1.svg'; 
import social2 from '../assets/img/nav-icon2.svg'; 
import social3 from '../assets/img/nav-icon3.svg';
 

export const Footer = () => {
    return (
        <frooter className="footer">
            <Container>
                <Row className="align-items-center mt-4">
                    <Col className="text-center">
                        <img src={logo} alt="Logo" />
                    </Col>
                    <Col className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/company/websco-digital"><img src={social1} /></a>
                            <a href="https://www.facebook.com/websco.tech"><img src={social2} /></a>
                            <a href="https://www.instagram.com/websco.digital/"><img src={social3} /></a>
                         </div>
                         <p>info@websco.digital</p>
                         <p>+57 3175741844</p>
                         <p>CopyRight 2023. Powered by Websco Digital</p>
                    </Col>
                </Row>
            </Container>
        </frooter>
    )
}