import React from 'react';
import play from "../assets/img/jugar.png"

const VideoCard = ({ title, imageUrl, videoUrl }) => (
  <div style={{ textAlign: "center", border: "solid 1px white", backgroundColor: 'transparent', color: '#fff', padding: '1rem' }}>
    <img src={imageUrl} alt={title} style={{ width: '100%', marginBottom: '0.5rem' }} />
    <h3 style={{ marginBottom: '0.5rem' }}>{title}</h3>
    <a className='playBtn' href={videoUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#fff', textDecoration: 'none' }}>PLAY</a>
  </div>
);

const VideoList = () => {
  const videos = [
    {
      title: 'Cine Tonalá Promotional Video',
      imageUrl: 'https://img.youtube.com/vi/fkwNbCHBuEM/sddefault.jpg',
      videoUrl: 'https://www.youtube.com/watch?v=fkwNbCHBuEM',
    },
    {
      title: 'Websco Digital Promotional Video',
      imageUrl: 'https://img.youtube.com/vi/0VDGe8TzIJA/sddefault.jpg',
      videoUrl: 'https://www.youtube.com/watch?v=0VDGe8TzIJA',
    },
    {
      title: 'Websco Digital Promotional Video',
      imageUrl: 'https://img.youtube.com/vi/Nybvxnt9ZvY/sddefault.jpg',
      videoUrl: 'https://www.youtube.com/watch?v=Nybvxnt9ZvY',
    },
    {
      title: "Cine Tonalá Promotional Video",
      imageUrl: 'https://img.youtube.com/vi/xrCPKJxyOm4/sddefault.jpg',
      videoUrl: 'https://www.youtube.com/watch?v=xrCPKJxyOm4',
    },
    {
      title: 'Apariencias Final Cut',
      imageUrl: 'https://img.youtube.com/vi/DPDkcfOWVgE/sddefault.jpg',
      videoUrl: 'https://www.youtube.com/watch?v=DPDkcfOWVgE',
    },
    {
      title: 'Rigpa Documentary',
      imageUrl: 'https://img.youtube.com/vi/mCzOB8GPBKc/sddefault.jpg',
      videoUrl: 'https://www.youtube.com/watch?v=mCzOB8GPBKc',
    },
  ];

  return (
    <div className='videoGrid' style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '1rem' }}>
      {videos.map(video => (
        <VideoCard key={video.title} title={video.title} imageUrl={video.imageUrl} videoUrl={video.videoUrl} />
      ))}
    </div>
  );
};

export default VideoList;
