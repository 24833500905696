import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { VideoSlider } from "./VideoSlider";
import VideoList from "../components/VideoList";
import GrapLine from "../components/GrapLine";

import projImg1 from "../assets/img/projImg1.png";
import projImg2 from "../assets/img/projImg2.png";
import projImg3 from "../assets/img/projImg3.png";
import projImg4 from "../assets/img/projImg4.png";
import projImg5 from "../assets/img/projImg5.png";
import projImg7 from "../assets/img/projImg7.png";
import projImg8 from "../assets/img/loix.png";
import projImg9 from "../assets/img/imasde.png";
import projImg10 from "../assets/img/ecolom.png";
import projImg11 from "../assets/img/melodic.png";
import projImg12 from "../assets/img/rosie.png";
import projImg13 from "../assets/img/sharks.png";









export const Projects = () => {

const projects = [
    {
        title: "Casa Dmente",
        description: "Design & Development",
        imgUrl: projImg3,
        link: <a href="http://www.casadmente.com">www.casadmente.com</a>
    },
    {
        title: "Eucoclean Global",
        description: "Design & Development",
        imgUrl: projImg2,
        link: <a href="http://www.eucocleanglobal.com">www.eucocleanglobal.com</a>

    },
    {
        title: "French Study",
        description: "Design & Development",
        imgUrl: projImg1,
        link: <a href="http://www.frenchstudy.co">www.frenshstudy.co</a>
   
    },
    {
        title: "Colombia Masc",
        description: "Design & Development",
        imgUrl: projImg4,
        link: <a href="http://www.colombiamasc.com">www.colombiamasc.com</a>

    },
    {
        title: "CondoNest",
        description: "Design & Development",
        imgUrl: projImg5,
        link: <a href="http://www.condonest.com">www.condonest.com</a>

    },
    {
        title: "Inhotelcol",
        description: "Design & Development",
        imgUrl: projImg7,        
        link: <a href="http://www.inhotelcol.org">www.inhotelcol.org</a>

    },
    {
        title: "Relojes Loix",
        description: "Design & Development",
        imgUrl: projImg8,        
        link: <a href="http://www.relojesloix.com">www.relojesloix.com</a>

    },
    {
        title: "I+de.co",
        description: "Design & Development",
        imgUrl: projImg9,        
        link: <a href="http://www.imasde.co">www.imasde.com</a>

    },
    {
        title: "Ecolombia Sustentable",
        description: "Design & Development",
        imgUrl: projImg10,        
        link: <a href="http://www.ecolombiasustentable.com">www.ecolombiasustentable.com</a>

    },
    {
        title: "Melodic Planet",
        description: "Design & Development",
        imgUrl: projImg11,        
        link: <a href="http://www.melodicplanet.site">www.melodicplanet.site</a>

    },
    {
        title: "Rosie Poliquin",
        description: "Design & Development",
        imgUrl: projImg12,        
        link: <a href="http://www.rosiepoliquin.com">www.rosiepoliquin.com</a>

    },
    {
        title: "Sharks Media",
        description: "Design & Development",
        imgUrl: projImg13,        
        link: <a href="http://www.sharksmedia.co">www.sharksmedia.com</a>

    },
];

return(
    <section className="project" id="projects">
        <Container>
            <Row>
                <Col>
                <h2>
                    Projects
                </h2>
                <p>These are some of the projects we have been involved in and in which we have developed digital solutions.</p>
    <Tab.Container id="projects-tabs" defaultActiveKey="first">    
        <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
            <Nav.Item>
                <Nav.Link eventKey="first">Web Design</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="second">Graphic Line</Nav.Link>
            </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="third">Video</Nav.Link>
      </Nav.Item>
    </Nav>
    <Tab.Content>
        <Tab.Pane eventKey="first">
            <Row>
                {
                    projects.map((project, index) => {
                        return (
                            <ProjectCard 
                                key={index}
                                {... project}
                            />

                        )
                    })
                }
            </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
        <GrapLine/>

        </Tab.Pane>
        
        <Tab.Pane eventKey="third">
              <VideoList />
        </Tab.Pane>
    </Tab.Content>
    </Tab.Container>           
    </Col>
    </Row>
    </Container>

    </section>
  );
}