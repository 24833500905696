import React from 'react';
import logo from '../assets/img/logo.png'


export const Banner2 = () => (
  <div className='banner2'>
    <img className='bannerlogo' src={logo} alt="prueba" />
  </div>
);


