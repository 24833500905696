import { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { ArrowRightCircle } from "react-bootstrap-icons"
import headerImg from "../assets/img/header-img.png"

export const Banner =() => {
   const [loopNum, setLoopNum] = useState(0);
   const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Webmasters", "Digital Marketers", "Web Solutions"];
    const [text, setText] = useState("");
    const[delta, setDelta] = useState(300 - Math.random() * 100)
    const period = 2000;

useEffect (()=> {
    let ticker = setInterval(() => {
        tick();

    },delta )

    return () => { clearInterval(ticker)};

}, [text])

const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1 ): fullText.substring(0, text.length + 1 );

    setText(updatedText);


    if(isDeleting){
        setDelta (prevDelta => prevDelta /2)
    }
    if (!isDeleting && updatedText === fullText){
    setIsDeleting(true);
    setDelta(period);
 }
 else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setDelta(500);

    }
}

    return (
        <section className="banner" id="home" >
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
        <span className="tagline">Welcome to</span>
        <h1>{'Websco Digital we are '}<span className="wrap">{text}</span></h1><br />
        <p>
Websco digital is a project that was born with a group of visual artists who come together to advise on the growth of any type of projects in the digital area.</p>
        <button onClick={()=> console.log ("connect")}>Let's Connect<ArrowRightCircle size={25}/></button>
        </Col>                   
        <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="header Img"/>        
            </Col>


        </Row>
        </Container> 
        </section>
    )  
}