import React from "react";
import Brazi1 from "../assets/img/brazi1.png";
import Brazi2 from "../assets/img/brazi2.jpg";
import Brazi3 from "../assets/img/brazi3.png";
import Manduka1 from "../assets/img/1.png";
import Manduka2 from "../assets/img/2.png";
import Manduka3 from "../assets/img/3.png";
import Bellissima from "../assets/img/bellissima.png"
import Apariencias from "../assets/img/afiche apariencias.png"
import Sharks from "../assets/img/sharks.jpg"
import Sharks2 from "../assets/img/sharks2.jpg"
import Sharks123 from "../assets/img/sharks123.jpg"
import Ouada from "../assets/img/poster.jpg"




const fotos = [
  {
    id: 1,
    titulo: "Brazi Web Campaign",
    imagen: Brazi1,
  },
  {
    id: 2,
    titulo: "Brazi Web Campaign",
    imagen: Brazi2,
  },
  {
    id: 3,
    titulo: "Brazi Web Campaign",
    imagen: Brazi3,
  },
  {
    id: 4,
    titulo: "Posada Manduka Branding - Web Design",
    imagen: Manduka1,
  },
  {
    id: 5,
    titulo: "Posada Manduka Branding - Web Design",
    imagen: Manduka2,
  },
  {
    id: 6,
    titulo: "Posada Manduka Branding - Web Design",
    imagen: Manduka3,
  },
  {
    id: 7,
    titulo: "Bellissima Branding - Web Design",
    imagen: Bellissima,
  },
  {
    id: 8,
    titulo: "Apariencias - Sound Design - Poster Design",
    imagen: Apariencias
  },
  {
    id: 9,
    titulo: "Sharks Media - Graphic Design",
    imagen: Sharks
  },
  {
    id: 9,
    titulo: "Sharks Poker - Graphic Design",
    imagen: Sharks2
  },
  {
    id: 10,
    titulo: "Sharks Media - Graphic Design",
    imagen: Sharks123
  },
  {
    id: 11,
    titulo: "Ouada - Graphic Design",
    imagen: Ouada
  },
 
];

const GridFotos = () => {
  return (
    <div className="grid-container">
      {fotos.map((foto) => (
        <div key={foto.id} className="grid-item">
          <img src={foto.imagen} alt={foto.titulo} />
          <p>{foto.titulo}</p>
        </div>
      ))}
    </div>
  );
};

export default GridFotos;
