import './App.css';
import {NavBar} from './components/NavBar'; 
import {Banner} from './components/Banner';
import {Skills} from './components/Skills';
import {Banner2} from './components/Banner2';
import{Projects} from './components/Projects';
import {Footer} from './components/Footer'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import { Contact } from './components/Contact';
import { VideoSlider } from './components/VideoSlider';


function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Banner2 />
      <Projects />
      <Contact />
      <Footer />

      
    </div>
  );
}

export default App;
